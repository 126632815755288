import type { VersionNotificationProps } from '../../components/Notification';
import type { ReadVersionType } from '@readme/api/src/mappings/version/types';

import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore, useSuperHubStore } from '@core/store';

import { getDirtyValues } from '@routes/SuperHub/Settings/forms/Project/utils';

import Button from '@ui/Button';
import Modal, { ModalFooter } from '@ui/Modal';
import { notify } from '@ui/Notification';
import { RHFGroup } from '@ui/RHF';

import VersionNotification from '../../components/Notification';
import SemVerInput from '../../components/SemVerInput';
import { useEditVersionFormContext } from '../../EditVersion/Context';
import useBuildReplacedVersionPath from '../../hooks/useBuildReplacedVersionPath';

import { useRenameVersionModalContext } from './Context';
import styles from './index.module.scss';

function ErrorNotification({
  apiError,
  name,
  newName,
}: {
  apiError: VersionNotificationProps['apiError'];
  name: string;
  newName: string;
}) {
  return (
    <VersionNotification
      apiError={apiError}
      description={
        <>
          Failed to rename v{name} to v{newName}. Please try again or{' '}
          <a href="mailto:support@readme.io" rel="noreferrer" target="_blank">
            reach out to support
          </a>
          .
        </>
      }
      kind="error"
      title="Problem Updating Version"
    />
  );
}

function SuccessNotification({ name, newName }: { name: string; newName: string }) {
  return (
    <VersionNotification
      description={`v${name} has been renamed to v${newName}.`}
      kind="success"
      title="Version Updated"
    />
  );
}

interface Props {
  /** Called when version is successfully renamed. */
  onSuccess?: (version: ReadVersionType['data']) => void;
}

export default function RenameVersionModal({ onSuccess }: Props) {
  const bem = useClassy(styles, 'RenameVersionModal');
  const uid = useUniqueId('RenameVersionModal');
  const modalContainerId = uid('modal');

  const modalRef = useRef<Modal>(null);
  const { isRenameModalOpen, setIsRenameModalOpen } = useRenameVersionModalContext();

  useEffect(() => {
    modalRef.current?.toggle(isRenameModalOpen);
  }, [isRenameModalOpen]);

  const buildReplacedVersionPath = useBuildReplacedVersionPath();
  const defaultVersion = useProjectStore(s => s.data.default_version.name);
  const [renameVersion, currentVersion, versions] = useSuperHubStore(s => [
    s.versions.renameVersion,
    s.versions.currentVersion,
    s.versions.data.data,
  ]);

  const formValue = useEditVersionFormContext();
  const versionName = formValue.formState.defaultValues?.name || '';

  const handleSubmit = formValue.handleSubmit(async data => {
    const defaultName = formValue.formState.defaultValues?.name;
    if (!defaultName) {
      throw new Error('Original version name is missing.');
    }

    // Trigger a form field error if version name is not unique.
    if (versions.some(version => version.name === data.name)) {
      formValue.setError('name', {
        type: 'value',
        message: 'Version already exists.',
      });
      return;
    }

    const dirtyData = getDirtyValues(formValue.formState.dirtyFields, data);
    const newName = dirtyData.name;

    // The required attribute on the name input should make this impossible,
    // but being extra safe for the compiler
    if (!newName) {
      formValue.setError('name', { type: 'required' });
      return;
    }

    try {
      const response = await renameVersion(defaultName, newName);
      formValue.reset(response.data);
      notify(<SuccessNotification name={defaultName} newName={newName} />);
      setIsRenameModalOpen(false);
      onSuccess?.(response.data);

      // if the current version was renamed, perform a redirect to replace the
      // version segment of the path with the new name
      if (
        newName !== versionName &&
        versionName !== defaultVersion && // default versions don't have a version segment in the path
        versionName === currentVersion.name
      ) {
        window.location.assign(buildReplacedVersionPath(newName));
      }
    } catch (error) {
      notify(<ErrorNotification apiError={error} name={defaultName} newName={newName} />);
    }
  });

  return (
    <>
      <Modal
        ref={modalRef}
        className={bem('-container')}
        noDismiss
        onOpen={() => formValue.setFocus('name')}
        size="sm"
        target={`#${modalContainerId}`}
        verticalCenter
      >
        <header className={bem('-header')}>
          <h1 className={bem('-title')}>Rename Version</h1>
        </header>
        <form
          onSubmit={e => {
            // don't allow the submit event to reach the parent edit form
            e.stopPropagation();
            handleSubmit(e);
          }}
        >
          <div className={bem('-body')}>
            <p>This will rename v{versionName} in your GitHub project and ReadMe. This action cannot be undone.</p>

            <RHFGroup control={formValue.control} id={uid('name')} isSemVer name="name" required>
              {({ field }) => <SemVerInput aria-label="Name" {...field} />}
            </RHFGroup>
          </div>

          <ModalFooter align="center" gap="sm" justify="center">
            <Button
              disabled={formValue.formState.isSubmitting}
              ghost
              kind="minimum"
              onClick={() => setIsRenameModalOpen(false)}
              size="md"
            >
              Cancel
            </Button>
            <Button
              kind="destructive"
              loading={formValue.formState.isSubmitting ? 'Renaming' : false}
              size="md"
              type="submit"
            >
              Rename
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      {createPortal(
        <div className={bem('&', 'ModalWrapper')} data-color-mode="dark" data-testid={bem()} id={modalContainerId} />,
        document.body,
      )}
    </>
  );
}
