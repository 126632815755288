import type { ForwardedRef } from 'react';

import React, { forwardRef, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import { SuperHubHashRoutePaths, type SuperHubHashRouteParams } from '@routes/SuperHub/types';

import Icon from '@ui/Icon';
import Segment, { SegmentItem } from '@ui/Segment';
import Truncate from '@ui/Truncate';

import BranchStatusIndicator from '../BranchStatusIndicator';

import classes from './index.module.scss';

const leafSegments: Record<
  NonNullable<Extract<SuperHubHashRouteParams['action'], 'appearance' | 'configure' | 'content'>>,
  {
    icon: string;
    label: string;
  }
> = {
  appearance: {
    icon: 'brush',
    label: 'Appearance',
  },
  configure: {
    icon: 'settings',
    label: 'Settings',
  },
  content: {
    icon: 'text',
    label: 'Content',
  },
};

/**
 * Displays a breadcrumb used to show the current location of the user within the project.
 * The first segment is the version/branch picker, and the second segment is the current settings section.
 */
export default forwardRef(function ProjectNavigationBreadcrumb(_, ref: ForwardedRef<HTMLDivElement>) {
  const bem = useClassy(classes, 'ProjectNavigationBreadcrumb');
  const [currentVersion] = useSuperHubStore(s => [s.versions.currentVersion]);

  const isActiveVersionHidden = currentVersion.privacy.view === 'hidden';

  const { hash } = useLocation();
  const match = matchPath<SuperHubHashRouteParams>(hash.substring(1), Object.values(SuperHubHashRoutePaths));
  const hashRouteAction = match?.params.action;

  /**
   * Indicates whether an additional breadcrumb segment exists based on our hash
   * route. Otherwise, only the single top-level `version` segment is shown.
   */
  const leafSegment = hashRouteAction ? leafSegments[hashRouteAction] : null;

  // We don't want to show the version/branch menu when the user is configuring the project level settings,
  // because those will not be tied to a specific version.
  const showVersionSegment = hashRouteAction !== 'configure';

  const selectedIndex = useMemo(() => {
    // When versions manager panel is enabled, we want the top-level version
    // breadcrumb to act like a toggle. It's unselected by default but
    // transitions to a selected state when the manager panel is open.
    if (hashRouteAction === 'versions') return 0;
    if (leafSegment && showVersionSegment) return 1;
    return -1;
  }, [hashRouteAction, leafSegment, showVersionSegment]);

  return (
    <Segment
      ref={ref}
      circular
      className={bem(!showVersionSegment && '_leaf-segment-only')}
      gap="10px"
      ghost
      selectedIndex={selectedIndex}
      size="xs"
    >
      {!!showVersionSegment && (
        <SegmentItem to={hashRouteAction === 'versions' ? '#/' : '#/versions'}>
          <Icon name="git-branch" size="sm" />
          <Truncate maxWidth="24ch">{`v${currentVersion.name}`}</Truncate>
          {!isActiveVersionHidden && <BranchStatusIndicator isMinimal />}
        </SegmentItem>
      )}

      {!!leafSegment && (
        <SegmentItem className={bem('-leaf-segment')}>
          <Icon name={leafSegment.icon} />
          {leafSegment.label}
        </SegmentItem>
      )}
    </Segment>
  );
});
