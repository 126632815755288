import type { ReadVersionCollectionType } from '@readme/api/src/mappings/version/types';
import type { ComponentProps } from 'react';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import BranchStatusIndicator from '@routes/SuperHub/components/ProjectNavigation/BranchStatusIndicator';

import Badge from '@ui/Badge';
import Icon from '@ui/Icon';

import EditVersion from '../EditVersion';
import useBuildVersionPath from '../hooks/useBuildVersionPath';

import styles from './index.module.scss';

interface Props extends ComponentProps<'li'> {
  version: ReadVersionCollectionType['data'][number];
}

export default function VersionsListItem({ version, ...rest }: Props) {
  const bem = useClassy(styles, 'VersionsListItem');
  const buildVersionPath = useBuildVersionPath();
  const currentVersion = useSuperHubStore(s => s.versions.currentVersion);

  const isLive = ['public', 'default'].includes(version.privacy.view);
  const isCurrent = version.name === currentVersion.name;
  const path = buildVersionPath(version.name);

  return (
    <li className={bem('&', isCurrent && '_current')} {...rest}>
      <a className={bem('-link')} href={path}>
        <span className={bem('-link-icon-container')}>
          {!!isCurrent && <Icon aria-label="Current version" name="check" />}
        </span>
        <span>v{version.name}</span>
        {!!isLive && <BranchStatusIndicator />}
        {version.privacy.view === 'default' && (
          <Badge allCaps ghost kind="dark">
            Default
          </Badge>
        )}
        {version.state === 'deprecated' && (
          <Badge allCaps ghost kind="dark">
            Deprecated
          </Badge>
        )}
        {version.release_stage === 'beta' && (
          <Badge allCaps ghost kind="dark">
            Beta
          </Badge>
        )}
      </a>

      <EditVersion className={bem('-edit-button')} version={version} />
    </li>
  );
}
