import type { HubResponseProps } from '@readme/iso';

import React, { useEffect, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import useFullStory from '@core/hooks/useFullStory';
import useWootric from '@core/hooks/useWootric';
import {
  ConnectProjectStoreToApi,
  ConnectProjectStoreToDocument,
  ConnectSuperHubStoreToRouter,
  InitializeSuperHubStore,
  InitializeSuperHubVersions,
  useSuperHubStore,
} from '@core/store';

import { SuperHubContextProvider } from './components/Context';
import ProjectNavigation from './components/ProjectNavigation';
import useHubEditModeTransition from './components/useHubEditModeTransition';
import WelcomeModal from './components/WelcomeModal';
import HashRouter from './HashRouter';
import classes from './index.module.scss';

interface SuperHubProps {
  children: React.ReactNode;
  currentVersion: HubResponseProps['gitVersion'];
  initialVersions?: HubResponseProps['versions'];
}

/**
 * Renders superhub content for admins with settings and edit access.
 */
function AdminContainer({ children, initialVersions }: SuperHubProps) {
  const bem = useClassy(classes, 'SuperHub');
  const [layout, myDevPanelExpanded] = useSuperHubStore(s => [s.layout, s.isMyDevPanelExpanded]);

  useEffect(() => {
    document.body.classList.add(bem('_no-scroll'));
    return () => {
      document.body.classList.remove(bem('_no-scroll'));
    };
  }, [bem]);

  useHubEditModeTransition();

  const myDevelopersLayoutClassName = useMemo(() => {
    if (layout && layout === 'my-developers') {
      return `_${layout}-${myDevPanelExpanded ? 'expanded' : 'collapsed'}`;
    }
    return undefined;
  }, [layout, myDevPanelExpanded]);

  // Enable FullStory & Wootric for SuperHub admins
  useFullStory();
  useWootric();

  return (
    <SuperHubContextProvider>
      <InitializeSuperHubVersions initialVersions={initialVersions}>
        <ConnectSuperHubStoreToRouter>
          <ConnectProjectStoreToApi>
            <ConnectProjectStoreToDocument>
              <div className={bem('&', '_admin', layout && `_${layout}`, myDevelopersLayoutClassName)}>
                <ProjectNavigation className={bem('-project-controls')} />
                <WelcomeModal />
                <div className={bem('-hub-wrapper')}>
                  <div className={bem('-hub-wrapper-overflow')}>{children}</div>
                </div>

                <HashRouter />
              </div>
            </ConnectProjectStoreToDocument>
          </ConnectProjectStoreToApi>
        </ConnectSuperHubStoreToRouter>
      </InitializeSuperHubVersions>
    </SuperHubContextProvider>
  );
}

/**
 * Renders superhub content for non-admin users with view-only access.
 */
function ViewContainer({ children, initialVersions }: SuperHubProps) {
  const bem = useClassy(classes, 'SuperHub');

  return (
    <SuperHubContextProvider>
      <InitializeSuperHubVersions initialVersions={initialVersions}>
        <ConnectSuperHubStoreToRouter>
          <div className={bem()}>{children}</div>
        </ConnectSuperHubStoreToRouter>
      </InitializeSuperHubVersions>
    </SuperHubContextProvider>
  );
}

function Content(props: SuperHubProps) {
  const [isSuperHub, isSuperHubAdmin] = useSuperHubStore(s => [s.isSuperHub, s.isSuperHubAdmin]);

  return isSuperHubAdmin ? (
    <AdminContainer {...props} />
  ) : isSuperHub ? (
    <ViewContainer {...props} />
  ) : (
    // For non-superhub projects, render content as is.
    <>{props.children}</>
  );
}

/**
 * Top-level wrapper provides our required context that all sub-components need.
 * This allows our top-level SuperHub component to also access context values.
 */
export default function SuperHub(props: SuperHubProps) {
  return (
    <InitializeSuperHubStore currentVersion={props.currentVersion}>
      <Content {...props} />
    </InitializeSuperHubStore>
  );
}
