import React from 'react';

import { useProjectStore, useSuperHubStore } from '@core/store';

import Icon from '@ui/Icon';
import { MenuItem } from '@ui/Menu';

import { useEditVersionFormContext } from '../EditVersion/Context';
import useBuildVersionPath from '../hooks/useBuildVersionPath';

import DeleteVersionModal from './Modal';
import { useDeleteVersionModalContext } from './Modal/Context';

/**
 * Renders the CTA to create a new version that opens up a form inside a
 * popover. When creation is successful, popover is hidden. If an error occurs,
 * popover remains open so the form can be resubmitted if needed.
 */
export default function DeleteVersion() {
  const currentVersion = useSuperHubStore(s => s.versions.currentVersion);
  const defaultVersion = useProjectStore(s => s.data.default_version.name);
  const formValue = useEditVersionFormContext();
  const { setIsDeleteModalOpen } = useDeleteVersionModalContext();
  const buildVersionPath = useBuildVersionPath();

  /** Whether the form contains a `default` version or not. */
  const isDefaultVersion = formValue.formState.defaultValues?.privacy?.view === 'default';

  return (
    <>
      <MenuItem
        aria-label="Delete Version"
        color="red"
        description={isDefaultVersion ? "Can't delete default versions" : undefined}
        disabled={isDefaultVersion}
        icon={<Icon aria-hidden="true" name="trash" />}
        onClick={() => setIsDeleteModalOpen(true)}
        TagName="button"
      >
        Delete
      </MenuItem>

      <DeleteVersionModal
        onDelete={name => {
          if (currentVersion.name === name) {
            window.location.assign(buildVersionPath(defaultVersion));
          }
        }}
      />
    </>
  );
}
